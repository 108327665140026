<template>
  <div>
    <Toolbar class="mb-4">
      <template #right> Sicht </template>
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/packagesuser')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label"> # </label>

          <input
            class="form-control"
            id="sexType"
            name="sexType"
            :value="$codePadding(body.id)"
            disabled
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label">HERR/FRAU</label>

          <input
            class="form-control"
            id="sexType"
            name="sexType"
            v-model="body.sexType"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="fname" class="form-label">Vorname </label>

          <input
            class="form-control"
            id="fname"
            name="fname"
            v-model="body.fname"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="lname" class="form-label">Nachname </label>

          <input
            class="form-control"
            id="lname"
            name="lname"
            v-model="body.lname"
            disabled
          />
        </div>
      </div>


      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Created At </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="$durationFormatFullDate(body.createdAt)"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Last Update At </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="$durationFormatFullDate(body.updatedAt)"
            disabled
          />
        </div>
      </div>
     
      


      
      <div class="col-md-10">
        <div class="mb-3">
          <label for="address" class="form-label"> Address Home </label>
          <input
            type="text"
            class="form-control"
            id="address"
            :value="body.address"
            disabled
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <label for="zip" class="form-label"> Zip </label>
          <input
            type="number"
            class="form-control"
            id="zip"
            :value.number="body.zip"
            disabled
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="city" class="form-label"> City </label>
          <input
            type="text"
            class="form-control"
            id="city"
            :value="body.city"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="country" class="form-label"> Country </label>
          <input
            type="text"
            class="form-control"
            id="country"
            :value="body.country"
            disabled
          />
        </div>
      </div>

      <!-- <div class="col-md-12">
        <div class="mb-3">
          <label for="phone" class="form-label"> phone </label>
          <input
            type="text"
            class="form-control"
            id="phone"
            :value="body.phone"
            disabled
          />
        </div>
      </div> -->
      <br />
      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label"> Massage Order</label>
      
          

          <div class="row m-2" v-for="(item, k) of list" :key="k">
            <div class="col-md-11">
              <input
                v-if="item.massageId"
                class="form-control"
                id="adminnote"
                name="adminnote"
                :value="
                  '#' +
                  item.id +
                  ' => ' +
                  JSON.parse(item.massageId.name).de +
                  ' @ ' +
                  item.pricesId.minutes +
                  ' Minutes = ' +
                  item.pricesId.price +
                  '€ ' +
                  ' (' +
                  $durationFormat(item.start) +
                  '  ' +
                  $durationFormatFull(item.start, item.end) +
                  ') Angestellte ' +
                  item.adminsId.fullName
                "
                disabled
              />
            </div>
            <div class="col-md-1">
              <Button
                icon="pi pi-eye"
                class="p-button-warning"
                @click="$router.push('/admin/massageorder/edit/' + item.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12 text-center">
        <Button
          label="Bearbeiten"
          icon="pi pi-pencil"
          class="ml-2 p-button-info"
          @click="update()"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        name: { en: null, de: null },
        body: { en: null, de: null },
        // email: null,
        inhomepage: 0,
        depoist: 0,
        adminnote: [
          {
            note: "",
          },
        ],
      },
      id: null,

      sectionList: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
      list: [],
    };
  },
  methods: {
    updateDone() {
      this.$http
        .put(`packages_users/${this.id}`, {
          paymentDone: true,
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    getData() {
      this.$http.get(`packages_users/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.getData();
      this.$http
        .post(`appointments/search?limit=100000000`, {
          packagesUsersId: this.id,
        })
        .then(
          (res) => {
            this.list = res.data.data.items;
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    }
  },
  // watch: {
  //   depoist(val) {
  //     if (val > 0) {

  //     }
  //   },
  // },
};
</script>
